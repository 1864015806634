<template>
  <div class="col-12 mt-16 mb-3">
    <div class="overflow-auto">
      <div class="mt-16">
        <b-pagination
          v-model="internalCurrentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          align="right"
          class="m-0"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from "bootstrap-vue";

export default {
  name: "PaginateAftaf",
  props: {
    currentPage: Number,
    perPage: Number,
    totalRows: Number,
  },
  components: {
    BPagination,
  },
  computed: {
    internalCurrentPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.$emit("update:currentPage", value);
        this.$emit("page-changed", value);
      },
    },
  },
};
</script>
