<template>
  <div class="col-12" style="overflow: auto; white-space: nowrap">
    <b-table
      :items="pendonorData"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      responsive="sm"
      show-empty
    >
      <!-- Kolom Status -->
      <template v-slot:cell(status)="row">
        <b-badge :variant="row.item.status === 'Rejected' ? 'danger' : 'info'">
          {{ row.item.status === "Rejected" ? "Ditolak" : "Diterima" }}
        </b-badge>
      </template>

      <!-- Kolom Aksi -->
      <template v-slot:cell(aksi)="row">
        <b-button-group>
          <!-- Tombol Detail -->
          <router-link :to="`/dashboards/detail-activasiaftaf/${row.item.id}`">
            <b-button size="sm" class="mx-1" variant="success-1">
              <b-icon icon="menu-up" class="mx-2" />
              Detail
            </b-button>
          </router-link>

          <!-- Tombol Laporan -->
          <router-link :to="`/print/${row.item.registration_id}`">
            <b-button size="sm" variant="outline-primary">
              <b-icon icon="menu-up" class="mx-2" />
              Laporan
            </b-button>
          </router-link>
        </b-button-group>
      </template>

      <!-- Template untuk saat tabel sedang memuat data -->
      <template #table-busy>
        <div class="hp-p1-body text-center my-2">
          <b-spinner class="align-middle mr-8" />
          <strong>Memuat...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BIcon,
  BButtonGroup,
  BBadge,
  BSpinner,
} from "bootstrap-vue";

export default {
  name: "TableListHB",
  components: {
    BTable,
    BButton,
    BIcon,
    BButtonGroup,
    BBadge,
    BSpinner,
  },
  props: ["isBusy", "pendonorData", "perPage", "currentPage"],
  data() {
    return {
      sortBy: "age",
      sortDesc: false,
      fields: [
        { label: "Status", key: "status", sortable: false },
        { label: "Id HB", key: "hb_examinations_id", sortable: true },
        { label: "Nama Pendonor", key: "name", sortable: true },
        { label: "Nomor Kantong", key: "blood_bag_number", sortable: true },
        { label: "Mulai", key: "venipuncture_start_time", sortable: true },
        { label: "Akhir", key: "venipuncture_end_time", sortable: true },
        { key: "aksi", sortable: false },
      ],
      items: [],
      navigate: [
        {
          name: "Detail",
          icon: "menu-up",
          variant: "success-1",
          to: "/dashboards/detail-activasiaftaf",
        },
      ],
    };
  },
};
</script>
