<template>
  <div class="col-12" style="overflow: auto; white-space: nowrap">
    <b-table
      :items="pendonorData"
      :per-page="perPage"
      :current-page="currentPage"
      :fields="fields"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      responsive="sm"
      show-empty
    >
      <template v-slot:cell(aksi)="row">
        <b-button-group v-for="(item, index) in navigate" :key="index">
          <router-link :to="`${item.to}/${row.item.hb_examinations_id}`">
            <b-button size="sm" class="mx-1" :variant="item.variant">
              <b-icon :icon="item.icon" class="mx-2" />
              {{ item.name }}
            </b-button>
          </router-link>
        </b-button-group>
      </template>
      <template v-slot:cell(status)="row">
        <b-badge
          :variant="row.item.status === 'Waiting' ? 'warning' : 'warning'"
        >
          {{ row.item.status === "Waiting" ? "Proses" : "Menunggu" }}
        </b-badge>
      </template>

      <template #table-busy>
        <div class="hp-p1-body text-center my-2">
          <b-spinner class="align-middle mr-8" />
          <strong>Memuat...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BIcon,
  BButtonGroup,
  BBadge,
  BSpinner,
} from "bootstrap-vue";

export default {
  name: "TableWaitingListAftaf",
  components: {
    BTable,
    BButton,
    BIcon,
    BButtonGroup,
    BBadge,
    BSpinner,
  },
  props: ["isBusy", "pendonorData", "perPage", "currentPage"],
  data() {
    return {
      sortBy: "age",
      sortDesc: false,
      fields: [
        { label: "Status", key: "status", sortable: false },
        { label: "Nama", key: "name", sortable: true },
        { label: "Alamat", key: "address", sortable: true },
        { key: "aksi", sortable: false },
      ],
      items: [],
      navigate: [
        {
          name: "Detail",
          icon: "menu-up",
          variant: "success-1",
          to: "/dashboards/activasiaftaf-form",
        },
      ],
    };
  },
};
</script>
