<template>
  <b-row>
    <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <template>
        <b-card class="mb-3">
          <b-col
            cols="12"
            class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
          >
            <h2>Daftar Waiting List Pendonor - Aftaf</h2>
            <b-button
              class="float-right"
              size="sm"
              variant="primary"
              @click="refreshData"
            >
              <b-icon icon="arrow-clockwise" class="mr-1" />
              Refresh
            </b-button>
          </b-col>
          <TableWaitingListAftaf
            :is-busy="isBusy"
            :pendonor-data="table.itemsWaiting"
          />
        </b-card>
      </template>
      <template>
        <b-card>
          <b-col
            cols="12"
            class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
          >
            <h2>Daftar List Pendonor - Aftaf</h2>
            <div>
              <span>Semua ( {{ table.items.length }} )</span>
              <div class="row no-gutters float-right w-full">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-black-50"
                      class="border-right-0 pr-0 pl-2"
                      @click="getAllAftaf"
                    >
                      <i class="ri-search-line remix-icon text-primary" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="table.search"
                    type="search"
                    placeholder="Search"
                    class="border-left-0"
                    @input="debouncedSearch"
                  />
                </b-input-group>
              </div>
            </div>
          </b-col>
          <TableListAftaf :is-busy="isBusy" :pendonor-data="table.items" />

          <PaginateAftaf
            :current-page.sync="table.currentPage"
            :per-page="table.perPage"
            :total-rows="table.total"
            @page-changed="onPageChange"
          />
        </b-card>
      </template>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BCard,
  BIcon,
} from "bootstrap-vue";
import PaginateAftaf from "../../components/common/Aftaf/PaginateAftaf.vue";
import TableListAftaf from "../../components/common/Aftaf/TableListAftaf.vue";
import TableWaitingListAftaf from "../../components/common/Aftaf/TableWaitingListAftaf.vue";
import tableDataMixins from "../../../../../../mixins/tableDataMixins";

export default {
  mixins: [tableDataMixins],
  name: "Donations",
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    PaginateAftaf,
    TableListAftaf,
    TableWaitingListAftaf,
    BCard,
    BIcon,
  },
  data() {
    return {
      items: [],
      itemsWaiting: [],
      searchTitle: "",
      debounceTimer: null,
    };
  },
  computed: {
    activasiAftafs() {
      return this.$store.state.manageActivasiAftaf.activasiAftafs;
    },
    totalActivationAftaf() {
      return this.$store.state.manageActivasiAftaf.totalPages;
    },
    isBusy() {
      return this.$store.state.manageActivasiAftaf.isBusy;
    },
  },
  methods: {
    async fetchActivasiAftaf() {
      this.table.loading = this.isBusy;
      await this.$store.dispatch("manageActivasiAftaf/getActivasiAftaf", {
        page: this.table.currentPage,
        per_page: this.table.perPage,
        search: this.table.search,
      });
      this.table.items = this.activasiAftafs;
      this.table.total = this.totalActivationAftaf;
    },
    async fetchWaitingAftaf() {
      this.table.loading = this.isBusy;
      await this.$store.dispatch("manageActivasiAftaf/getWaitingAftaf", {
        per_page: this.table.perPage,
      });
      this.table.itemsWaiting = this.activasiAftafs;
      this.table.total = this.totalActivationAftaf;
    },
    async fetchData() {
      await this.fetchActivasiAftaf();
      await this.fetchWaitingAftaf();
    },
    debouncedSearch() {
      clearTimeout(this.table.debounceTimer);
      this.table.debounceTimer = setTimeout(() => {
        this.onChangeSearchTable();
      }, 500);
    },
    onChangeSearchTable() {
      if (this.table.search.trim() !== "") {
        this.getAllAftaf();
      } else {
        this.getAllAftaf();
      }
    },
    getAllAftaf() {
      console.log("Fetching Aftafs with search term:", this.table.search);
      this.fetchActivasiAftaf();
    },
    onPageChange(newPage) {
      this.table.currentPage = newPage;
      this.fetchData();
    },
    refreshData() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
